import * as React from 'react';
import * as Redux from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import * as CONST from '../../../constants/FieldsConst';
import Box from '@mui/material/Box';
import { saveCodigosAction } from '../CodigosAction';
import { validFielddHelper } from '../../../helpers/ValidFieldsHelper';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import LoadingComponent from '../../../components/Loading';
import { useNotification } from '../../../helpers/notification';
import useFetch from 'http-react';
import { baseApi, token } from '../../../services/Apis';

const FormCodigos = ({ setOpenModalForm, params }) => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const codigosStore = Redux.useSelector((state) => state.codigos);

  const activo = codigosStore.activo;

  const [codigo, setCodigo] = React.useState({
    [CONST.CODE]: '',
    [CONST.STATUS]: 'ACTIVE',
    [CONST.CODE_TYPE]: 'client',
    [CONST.ARREARS]: 0,
    levelId: ''
  });
  const [dataError, setDataRequired] = React.useState({
    [CONST.CODE]: false,
  });
  const [loadingSave, setLoadingSave] = React.useState(false);

  const { data: codeLevels } = useFetch(`${baseApi}/code-levels`, {
    headers: token({}),
    query: {
      entityId: entidadesStore.activo.id
    },
    onResolve: (data) => {
      if (data?.data?.length > 0 && !activo) {
        setCodigo({ ...codigo, levelId: data.data[0].id });
      }
    }
  })

  React.useEffect(() => {
    if (activo === null) return;
    let d = {
      [CONST.CODE]: activo.code,
      [CONST.STATUS]: activo.status,
      [CONST.ARREARS]: activo.arrears,
      [CONST.CODE_TYPE]: activo.codeType,
      levelId: activo.level?.id || codeLevels?.data[0]?.id
    };
    setTimeout(() => {
      setCodigo(d);
    }, 300);
  }, [activo]);

  const _handleSetDataFieldCodigo = (key, value) =>
    setCodigo({ ...codigo, [key]: value });

  const _validDataForm = () => {
    let r = {};
    let va = [];
    Object.keys(dataError).map((key) => {
      const val = codigo[key];
      const valid = val === null || val === '' || val === 0;
      r[key] = valid;
      return va.push(valid);
    });
    setDataRequired(r);
    return !va.includes(true);
  };

  const handleSubmit = async () => {
    const valid = await _validDataForm();
    if (valid) {
      const data = {
        [CONST.CODE]: codigo.code,
        [CONST.STATUS]: codigo.status,
        [CONST.ARREARS]: codigo.arrears,
        [CONST.CODE_TYPE]: codigo.codeType,
        [CONST.ENTITY_ID]: entidadesStore.activo.id,
        levelId: codigo.levelId
      };
      setLoadingSave(true);
      const id = activo === null ? null : activo.id;
      let res = await saveCodigosAction(
        { dispatch, addNotification },
        id,
        data,
        params
      );
      if (res) setOpenModalForm(false);
      setLoadingSave(false);
    }
  };

  return (
    <Box component="form" sx={{}}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <LoadingComponent
            isLoading={loadingSave}
            text={codigosStore.activo ? 'Actualizando...' : 'Guardando...'}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <TextField
                fullWidth
                size="small"
                id={CONST.CODE}
                required
                label="Código"
                value={codigo.code}
                onChange={(e) =>
                  _handleSetDataFieldCodigo(CONST.CODE, e.target.value)
                }
                helperText={dataError.code ? validFielddHelper(1) : null}
                error={dataError.code}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id={CONST.CODE_TYPE}>Tipo de usuario</InputLabel>
                <Select
                  labelId={CONST.CODE_TYPE}
                  size="small"
                  label="Tipo de usuario"
                  value={codigo.codeType}
                  disabled={['admin', 'client'].includes(codigo?.codeType)}
                  onChange={(e) =>
                    _handleSetDataFieldCodigo(CONST.CODE_TYPE, e.target.value)
                  }
                  id={CONST.CODE_TYPE}
                >
                  <MenuItem value="owner">Propietario</MenuItem>
                  {entidadesStore.entityType === 'Club' ? (
                    <MenuItem value="entity">Titular</MenuItem>
                  ) : null}
                  <MenuItem value="security">Seguridad</MenuItem>
                  {codigo?.codeType === 'admin' && (
                    <MenuItem value="admin">Administrador</MenuItem>
                  )}
                  {codigo?.codeType === 'client' && (
                    <MenuItem value="client">Cliente</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id={CONST.STATUS}>Estatus</InputLabel>
                <Select
                  labelId={CONST.STATUS}
                  size="small"
                  label="Estatus"
                  value={codigo.status}
                  onChange={(e) =>
                    _handleSetDataFieldCodigo(CONST.STATUS, e.target.value)
                  }
                  id={CONST.STATUS}
                >
                  <MenuItem value="ACTIVE">Activo</MenuItem>
                  <MenuItem value="BLOCKED">Bloqueado</MenuItem>
                  <MenuItem value="DEBT">Deudor</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="levelId">Nivel</InputLabel>
                <Select
                  labelId="levelId"
                  size="small"
                  label="Nivel"
                  value={codigo.levelId}
                  onChange={(e) =>
                    _handleSetDataFieldCodigo('levelId', e.target.value)
                  }
                  id="levelId"
                >
                  {codeLevels?.data?.map((item) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} align="center" sx={{ m: 2 }}>
          <BotonNuevoComponent
            click={handleSubmit}
            disabled={loadingSave}
            text="GUARDAR"
            morado
          />
        </Grid>
      </Grid>
    </Box>
  );
};

FormCodigos.propTypes = {
  setOpenModalForm: PropTypes.func,
};

export default FormCodigos;
