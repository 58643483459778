import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import * as Redux from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Modal } from '../../../components';
import ActionsList from '../../../components/ActionsList';
import LoadingComponent from '../../../components/Loading';
import ReservationUsedBlocks from '../../../components/ReservationUsedBlocks';
import * as CONST from '../../../constants/FieldsConst';
import { dateformat, getDateFormated, mesesArray } from '../../../helpers';
import { downloadExcelFile } from '../../../helpers/exportHelper';
import { useNotification } from '../../../helpers/notification';
import {
  GET_DATA_SEARCH_AREAS_PRECIOS_CUSTOM,
  GET_DATE_SEARCH_PRECIOS_CUSTOM,
} from '../../../store/Types';
import BotonNuevoComponent from '../../entidades/components/BotonNuevo';
import { textLabelsTable } from '../../invitados/components/ListadoInvitados';
import { reservations } from '../../reservas/ReservasService';
import { NameComponent } from '../../usuarios/components/ListadoUsuarios';
import {
  deletePreciosCustomAction,
  getAllPreciosCustomAction,
  setActivoPreciosCustomAction,
} from '../PreciosCustomAction';
import { SU } from '../../../constants/ConfigConst';
import FormPreciosCustom from './FormPreciosCustom';
import { isManagerUserCode } from '../../modulos/modules.helpers';

const ListadoPreciosCustom = () => {
  const dispatch = Redux.useDispatch();
  const { addNotification } = useNotification();
  const history = useHistory();
  const loginStore = Redux.useSelector((state) => state.login);

  const entidadesStore = Redux.useSelector((state) => state.entidades);
  const areasStore = Redux.useSelector((state) => state.areas);
  const preciosCustomStore = Redux.useSelector((state) => state.preciosCustom);
  
  const isRoot = loginStore.user.roles.some((r) => r.name === SU);
  const isManager =
    !isRoot &&
    isManagerUserCode(loginStore.user.userCodes, entidadesStore.activo?.id);

  const queryParams = new URLSearchParams(history.location.search);
  const areaId = Number(queryParams.get('areaId')) || null;

  const [area, setArea] = React.useState('');
  const [areaload, setAreaLoad] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [isParentBlock, setIsParentBlock] = React.useState(false);

  const [openModalForm, setOpenModalForm] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);

  const timezone = entidadesStore.activo.country.timezone;

  const [blockMode, setBlockMode] = React.useState(false);

  React.useEffect(() => {
    setArea(areaId ?? preciosCustomStore.dataSearchAreaId ?? '');
    if (areaId) {
      history.replace('/precios_personalizados');
    }

    setTimeout(() => {
      setAreaLoad(true);
    }, 500);

    if (preciosCustomStore.dateSearch && preciosCustomStore.dataSearchAreaId) {
      getAllPreciosCustomAction(dispatch, entidadesStore.activo.id, {
        areaId: preciosCustomStore.dataSearchAreaId,
        startDate: dayjs(preciosCustomStore.dateSearch).startOf('month').toDate(),
        endDate: dayjs(preciosCustomStore.dateSearch).endOf('month').toDate(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preciosCustomStore.dateSearch, preciosCustomStore.dataSearchAreaId]);

  React.useEffect(() => {
    setTimeout(() => {
      dispatch({
        type: GET_DATE_SEARCH_PRECIOS_CUSTOM,
        payload: dateformat(null, {}),
      });
    }, 400);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const rows = preciosCustomStore.all.map((customPrice, i) => {

      const stringDate = getDateFormated(customPrice.startDate, 'DD-MM-YYYY', timezone);
      const stringStartTime = getDateFormated(customPrice.startDate, 'hh:mm A', timezone);
      const stringEndTime = getDateFormated(customPrice.endDate, 'hh:mm A', timezone);

      return {
        ...customPrice,
        date: `${stringDate} ${stringStartTime} - ${stringEndTime}`,
      }
    });
    setRows(rows);
  }, [preciosCustomStore.all]);

  const _handleDeleteClick = async () => {
    setLoadingDelete(true);
    await deletePreciosCustomAction(
      { dispatch, addNotification },
      entidadesStore.activo.id,
      preciosCustomStore.activo.id,
      { parent: isParentBlock },
      {
        areaId: preciosCustomStore.dataSearchAreaId,
        startDate: dayjs(preciosCustomStore.dateSearch).startOf('month').toDate(),
        endDate: dayjs(preciosCustomStore.dateSearch).endOf('month').toDate(),
      }
    );
    setIsDelete(false);
    setLoadingDelete(false);
  };

  const columns = [
    {
      name: CONST.ID,
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'parentBlockId',
      options: {
        display: 'excluded',
      },
    },
    {
      name: 'name',
      label: 'Motivo',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <NameComponent>{value}</NameComponent>;
        },
      },
    },
    {
      name: 'price',
      label: 'Precio',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'subarea',
      label: 'Subárea',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <NameComponent>{value?.name}</NameComponent>;
        },
      },
    },
    {
      name: 'date',
      label: 'Fecha',
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: CONST.ACTIONS,
      label: 'Acción',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box align="left" sx={{ display: 'flex', flexDirection: 'row' }}>
                <ActionsList
                  id={tableMeta.rowData[0]}
                  onDelete={async () => {
                    const data = preciosCustomStore.all.find(
                      (el) => el.id === tableMeta.rowData[0]
                    );
                    setIsParentBlock(true);
                    await setActivoPreciosCustomAction(dispatch, data);
                    setIsDelete(true);
                  }}
                />
              </Box>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    setTableProps: () => {
      return {
        padding: 'none',
        size: 'small',
      };
    },
    onDownload: () => {
      const url = `${reservations}/export`;
      const name = 'reservaciones';
      const params = {
        entityId: entidadesStore.activo.id,
        areaId: preciosCustomStore.dataSearchAreaId,
        startDate: dayjs(preciosCustomStore.dateSearch).startOf('month').toDate(),
        endDate: dayjs(preciosCustomStore.dateSearch).endOf('month').toDate(),
        type: 'block',
        groupByBlockId: false,
      };
      downloadExcelFile(url, params, name, addNotification);
      return false;
    },
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    textLabels: textLabelsTable,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return <></>;
    },
    selectableRowsHideCheckboxes: true,
  };

  const selectAreas = areaload && (
    <FormControl size="small" sx={{ width: '100%' }}>
      <InputLabel id="selectAreas">Áreas</InputLabel>
      <Select
        value={area}
        labelId="selectAreas"
        label="Areas"
        size="small"
        onChange={(e) => {
          dispatch({
            type: GET_DATA_SEARCH_AREAS_PRECIOS_CUSTOM,
            payload: e.target.value,
          });
        }}
        id={CONST.AREA_ID}
      >
        {areasStore.all.map((el) => {
          return (
            <MenuItem key={`areas-${el.id}`} value={el.id}>
              {el.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const selectMes = (
    <FormControl size="small" sx={{ width: '100%' }}>
      <InputLabel id="selectMeses">Mes</InputLabel>
      <Select
        labelId="selectMeses"
        size="small"
        value={dateformat(preciosCustomStore.dateSearchOrigin, {
          format: 'MM',
        })}
        label="Mes"
        onChange={(e) => {
          const data = `${dateformat(null, { format: 'YYYY' })}-${
            e.target.value
          }-05`;
          dispatch({
            type: GET_DATE_SEARCH_PRECIOS_CUSTOM,
            payload: dateformat(data, {}),
          });
        }}
        id={CONST.DATE_TO}
      >
        {mesesArray.map((mes, index) => (
          <MenuItem key={index + 1} value={mes.valor}>
            {mes.nombre}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <Paper elevation={4} sx={{ p: 2 }}>
          <Grid container spacing={1}>
            {/* {!isManager && (
            )} */}
            <BotonNuevoComponent
              click={async () => {
                // history.push(ROUTES.panelPreciosCustom.to);
                setOpenModalForm(true);
                
              }}
              azul
              mr={2}
            />
            <BotonNuevoComponent
              click={async () => {
                // await setActivoAreasAction(dispatch, null);
                // await getAllAreasAction(dispatch, areasStore.params);
                await getAllPreciosCustomAction(
                  dispatch,
                  entidadesStore.activo.id,
                  {
                    areaId: preciosCustomStore.dataSearchAreaId,
                    date: dayjs(preciosCustomStore.dateSearch)
                      .startOf('month')
                      .toDate(),
                    dateTo: dayjs(preciosCustomStore.dateSearch)
                      .endOf('month')
                      .toDate(),
                  }
                );
              }}
              white
              disabled={preciosCustomStore.loadingGetAll}
              text={
                preciosCustomStore.loadingGetAll
                  ? 'Actualizando...'
                  : 'Actualizar'
              }
            />
            <Grid item xs={12} md={3}>
              {selectAreas}
            </Grid>
            <Grid item xs={12} md={3}>
              {!blockMode && selectMes}
            </Grid>
            {/* <Grid item xs={12} md={3}>
              <Stack direction="row" spacing={2}>
                <Typography>Lista</Typography>
                <Switch
                  checked={blockMode}
                  onChange={(e) => setBlockMode(e.target.checked)}
                />
                <Typography>Bloques</Typography>
              </Stack>
            </Grid> */}
          </Grid>
        </Paper>
      </Grid>
      <Grid item md={12}>
        <LoadingComponent
          isLoading={preciosCustomStore.loadingGetAll || loadingDelete}
          text="Cargando reservas..."
        />
        {!blockMode ? (
          <MUIDataTable
            title={`Listado de precios`}
            data={rows}
            columns={columns}
            options={options}
          />
        ) : (
          <ReservationUsedBlocks
            allDates
            areaIds={[preciosCustomStore.dataSearchAreaId]}
            interactive
          />
        )}
      </Grid>
      {preciosCustomStore.activo && (
        <Modal
          id="modalDeleteReserva"
          title={`Eliminar precio`}
          open={isDelete}
          maxWidth="sm"
          onClose={() => setIsDelete(!isDelete)}
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              ¿Seguro que quieres eliminar este bloque de reserva?
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '15px',
                }}
              >
                <div>
                  Motivo:&nbsp;&nbsp;&nbsp;
                  <strong>{preciosCustomStore.activo.description}</strong>
                </div>
                <div>
                  Entrada:&nbsp;&nbsp;&nbsp;
                  <strong>
                    {getDateFormated(
                      preciosCustomStore.activo.entryTime,
                      'YYYY-MM-DD hh:mm A',
                      timezone
                    )}
                  </strong>
                </div>
                <div>
                  Salida:&nbsp;&nbsp;&nbsp;
                  <strong>
                    {getDateFormated(
                      preciosCustomStore.activo.departureTime,
                      'YYYY-MM-DD hh:mm A',
                      timezone
                    )}
                  </strong>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={_handleDeleteClick}
            >
              ELIMINAR
            </Button>
            <Button onClick={() => setIsDelete(false)}>CANCELAR</Button>
          </DialogActions>
        </Modal>
      )}
      
      {!isManager && (
        <Modal
          id="modalFormReservaEspeciales"
          title={`${
            preciosCustomStore.activo ? 'Editar' : 'Crear'
          } precios personalizados`}
          open={openModalForm}
          maxWidth="sm"
          onClose={() => setOpenModalForm(!openModalForm)}
        >
          <FormPreciosCustom
            setIsMobile={() => setIsMobile(!isMobile)}
            setOpenModalForm={setOpenModalForm}
          />
        </Modal>
      )}
    </Grid>
  );
};

export default ListadoPreciosCustom;
